import React from "react";
import TabbedPanel from "../../components/tabbed-panel/tabbed-panel";
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "../../components/panel/panel.jsx";
import moment from "moment";
import HttpCliente from "../../services/HttpCliente";
import "./style.scss";
import axios from "axios";
import StatusResetPopup from "../../components/popups/status-reset-popup";
import NewCommentPopup from "../../components/popups/new-comment-popup";
import { Link, useParams, useNavigate } from "react-router-dom";
import ModalGeneral from "../modal/modalGeneral";
import { AppSettings } from "../../config/app-settings";

const RequestForm = (parm) => {
  const PageSettingsContext = React.useContext(AppSettings);
  let { kyc_id } = useParams();
  let edit_mode = parm.editMode;
  let path = window.location.pathname;

  //itd 256 begin
  let typeEdit = localStorage.getItem("typeEdit");
  const [temp_typeEdit, set_typeEdit] = React.useState(typeEdit);

  React.useEffect(() => {

    const onPageLoad = () => {
      set_typeEdit(typeEdit);
      localStorage.removeItem("typeEdit");
    };

    // Check if the page has already loaded
    if (document.readyState === 'complete') {
      onPageLoad();
    } else {
      window.addEventListener('load', onPageLoad);
      // Remove the event listener when component unmounts
      return () => window.removeEventListener('load', onPageLoad);
    }
  }, []);
  //itd 256 end

  path = path.slice(0, path.lastIndexOf("/"));
  path = path.slice(0, path.lastIndexOf("/"));
  ////////////////////////////////////////////////DECLARING STATE VARIABLE////////////////////////////////////////////////////////////////////////////////////////
  const editMode = edit_mode === "edit" ? true : false;

  const [temp_kyc_id, set_temp_kyc_id] = React.useState(kyc_id);
  // const [kyc_id, set_kyc_id] = React.useState(kyc_id);
  // const [mainRequestData, setMainRequestData] = React.useState([]);
  const [historyData, setHistoryData] = React.useState([]);
  const [
    kyc_status_customer_name,
    set_kyc_status_customer_name,
  ] = React.useState();
  const [bank_account_cci, set_bank_account_cci] = React.useState();
  // const [bank_account_currency, set_bank_account_currency] = React.useState();
  const [statusResetPopup, toggleStatusResetPopup] = React.useState(false);
  const [newCommentPopup, toggleNewCommentPopup] = React.useState(false);
  const [bank_account_number, set_bank_account_number] = React.useState();
  const [bank_code, set_bank_code] = React.useState();
  const [customer_dob, set_customer_dob] = React.useState();
  const [customer_email, set_customer_email] = React.useState();
  // const [customer_firstname, set_customer_firstname] = React.useState();
  const [customer_fullname, set_customer_fullname] = React.useState();
  const [customer_registry, set_customer_registry] = React.useState();
  const [customer_id, set_customer_id] = React.useState();
  const [customer_id_number, set_customer_id_number] = React.useState();
  const [customer_id_type, set_customer_id_type] = React.useState();
  const [errorTooltip, set_errorTooltip] = React.useState('');
  // const [customer_lastname1, set_customer_lastname1] = React.useState();
  // const [customer_lastname2, set_customer_lastname2] = React.useState();
  const [customer_province, set_customer_province] = React.useState();
  const [id, set_id] = React.useState();
  const [
    kyc_status_bank_account,
    set_kyc_status_bank_account,
  ] = React.useState();
  const [kyc_status_bank_name, set_kyc_status_bank_name] = React.useState();
  const [kyc_status_customer_id, set_kyc_status_customer_id] = React.useState();
  const [kyc_status_id, set_kyc_status_id] = React.useState();
  const [kyc_status_notes, set_kyc_status_notes] = React.useState();
  const [kyc_status_user_notes, set_kyc_status_user_notes] = React.useState();
  const [method_bank_account, set_method_bank_account] = React.useState();
  const [method_customer_id, set_method_customer_id] = React.useState();
  const [partner_id, set_partner_id] = React.useState();
  const [reniec_answer, set_reniec_answer] = React.useState();
  const [request_date, set_request_date] = React.useState();
  const [request_reference, set_request_reference] = React.useState();
  const [status_id, set_status_id] = React.useState();
  // const [status, set_status] = React.useState();
  const [activeTab, setActiveTab] = React.useState(0);
  // const [locked, set_locked] = React.useState(false);
  // const [locked_by, set_locked_by] = React.useState("");
  // const [locked_date, set_locked_date] = React.useState();
  const [
    sweetAlertActiveVariant2,
    setSweetAlertActiveVariant2,
  ] = React.useState(false);
  const [
    sweetAlertSuccessVariant2,
    setSweetAlertSuccessVariant2,
  ] = React.useState(false);
  const [
    sweetAlertMessage1Variant2,
    setSweetAlertMessage1Variant2,
  ] = React.useState("Temp");
  const [
    sweetAlertMessage2Variant2,
    setSweetAlertMessage2Variant2,
  ] = React.useState("Default");
  const [
    sweetAlertMessage3Variant2,
    setSweetAlertMessage3Variant2,
  ] = React.useState("Default");
  const [
    sweetAlertActiveVariant3,
    setSweetAlertActiveVariant3,
  ] = React.useState(false);
  const [
    sweetAlertSuccessVariant3,
    setSweetAlertSuccessVariant3,
  ] = React.useState(false);
  const [
    sweetAlertMessage1Variant3,
    setSweetAlertMessage1Variant3,
  ] = React.useState("Temp");
  const [
    sweetAlertMessage2Variant3,
    setSweetAlertMessage2Variant3,
  ] = React.useState("Temp");
  const [statusRequestData, setStatusRequestData] = React.useState();
  const [statusKycData, setStatusKycData] = React.useState();
  const [statusNoteData, setStatusNoteData] = React.useState();
  const [predifinedNote, setPredifinedNote] = React.useState();
  const [bankData, setBankData] = React.useState();
  const [partnerData, setPartnerData] = React.useState();
  const [selectedResetRecords, setSelectedResetRecords] = React.useState([]);
  // // const [resetButtonState, setResetButtonState] = React.useState(false);
  const [
    reniecStatusButtonsDisabled,
    setReniecStatusButtonsDisabled,
  ] = React.useState(true);
  const [sweetAlertActive, setSweetAlertActive] = React.useState(false);
  const [sweetAlertMessage, setSweetAlertMessage] = React.useState("Default");
  const [alertType, setAlertType] = React.useState("");
  const [sweetAlertSuccessBool, setSweetAlertSuccessBool] = React.useState(
    false
  );
  const [sweetAlertSuccess, setSweetAlertSuccess] = React.useState("");
  ////////////////////////////////////////////////SETTER FUNCTIONS FOR RESET STATUS STATES////////////////////////////////////////////////////////////////////////////////////////
  // const removeRecordToReset = (recordToRemove) => {
  //   let filteredArray = selectedResetRecords.filter(
  //     (item) => item !== recordToRemove
  //   );
  //   setSelectedResetRecords(filteredArray);
  // };
  const addRecordToReset = (recordToAdd) => {
    setSelectedResetRecords([recordToAdd]);
  };
  ////////////////////////////////////////////////LOADING ANIMATION FUNCTION////////////////////////////////////////////////////////////////////////////////////////
  // const loadingAnimation = () => {
  //   return (
  //     <div style={{ padding: "15vh 0% 15vh 38vw" }}>
  //       <Loading />
  //     </div>
  //   );
  // };
  ////////////////////////////////////////////////SWEET ALERTS TOGGLE HANDLER////////////////////////////////////////////////////////////////////////////////////////
  const toggleSweetAlert = (message, success, type) => {
    setSweetAlertSuccessBool(success);
    setSweetAlertSuccess(type);
    setSweetAlertActive(!sweetAlertActive);
    setSweetAlertMessage(message);
  };

  const toggleSweetAlertVariant2 = (message, message2, message3, type) => {
    setSweetAlertActiveVariant2(!sweetAlertActiveVariant2);
    setSweetAlertSuccessVariant2(type);
    setSweetAlertMessage1Variant2(message);
    setSweetAlertMessage2Variant2(message2);
    setSweetAlertMessage3Variant2(message3);
  };

  const toggleSweetAlertVariant3 = (message1, message2, type) => {
    setSweetAlertSuccessVariant3(type);
    setSweetAlertActiveVariant3(!sweetAlertActiveVariant3);
    setSweetAlertMessage1Variant3(message1);
    setSweetAlertMessage2Variant3(message2);
  };
  ////////////////////////////////////////////////SETTING UP COMPONENT MOUNTING DATA////////////////////////////////////////////////////////////////////////////////////////
  React.useEffect(() => {
    if (typeof window !== `undefined`) {
      window.scrollTo(0, 0);
    }
    HttpCliente.get(`/request/${kyc_id}`)
      .then((response) => {
        // setMainRequestData(response.data);
        setTimeout(() => {
          if (response.data.locked === true && editMode === true) {
            // set_locked(response.data.locked);
            // set_locked_by(response.data.locked_by);
            // // set_locked_date(response.data.locked_date);
            const m1 = `This record is being edited`;
            const m2 = `By ${response.data.locked_by} at ${moment
              .unix(response.data.locked_date)
              .format("DD/MM/YYYY hh:mm A")}`;
            const m3 = `Do you want to edit it anyway?`;
            toggleSweetAlertVariant2(m1, m2, m3, "info");
          }
        }, 500);
        if (editMode === true && [2, 10].includes(response.data.status_id)) {
          // setResetButtonState(true);
        }
        if (editMode === true && response.data.reniec_answer !== true) {
          setReniecStatusButtonsDisabled(false);
        }
      })
      .catch((error) => {
      });
  }, []);

  React.useEffect(() => {
    if (typeof window !== `undefined`) {
      window.scrollTo(0, 0);
    }
    HttpCliente.get(`/request/${kyc_id}`)
      .then((response) => {
        // setMainRequestData(response.data);
        //console.log(response.data);
        // console.log("response.data.status_id", response.data.status_id);
        // setTimeout(() => {
        //   if (response.data.locked === true && editMode === true) {
        //     set_locked(response.data.locked);
        //     set_locked_by(response.data.locked_by);
        // //     set_locked_date(response.data.locked_date);
        //     const m1 = `This KYC Request record is being edited by ${
        //       response.data.locked_by
        //     } at ${moment
        //       .unix(response.data.locked_date)
        //       .format("DD/MM/YYYY HH:mm")}`;
        //     const m2 = `Do you want to edit it anyway?`;
        //     toggleSweetAlertVariant2(m1, m2, "info");
        //   }
        // }, 500);
        // if (sweetAlertActiveVariant2 === false) {
        //   if (response.data.status_id !== 3) {
        //     setEditMode(false);
        //   } else {
        //     setEditMode(true);
        //   }
        // }
        set_bank_account_cci(response.data.bank_account_cci);
        // set_bank_account_currency(response.data.bank_account_currency);
        set_bank_account_number(response.data.bank_account_number);
        set_bank_code(response.data.bank_code);
        set_customer_dob(response.data.customer_dob);
        set_customer_email(response.data.customer_email);
        // set_customer_firstname(response.data.customer_firstname);
        set_customer_fullname(response.data.customer_fullname);
        set_customer_registry(response.data.customer_registry);
        set_customer_id(response.data.customer_id);
        set_customer_id_number(response.data.customer_id_number);
        set_customer_id_type(response.data.customer_id_type);
        // set_customer_lastname1(response.data.customer_lastname1);
        // set_customer_lastname2(response.data.customer_lastname2);
        set_customer_province(response.data.customer_province);
        set_id(response.data.id);
        set_kyc_status_bank_account(response.data.kyc_status_bank_account);
        set_kyc_status_bank_name(response.data.kyc_status_bank_name);
        set_kyc_status_customer_id(response.data.kyc_status_customer_id);
        set_kyc_status_customer_name(response.data.kyc_status_customer_name);
        set_kyc_status_id(response.data.kyc_status_id);
        set_kyc_status_notes(response.data.kyc_status_notes);
        set_kyc_status_user_notes(response.data.kyc_status_user_notes);
        // set_locked_date(response.data.locked_date);
        set_method_bank_account(response.data.method_bank_account);
        set_method_customer_id(response.data.method_customer_id);
        set_partner_id(response.data.partner_id);
        set_reniec_answer(response.data.reniec_answer);
        set_request_date(response.data.request_date);
        set_request_reference(response.data.request_reference);
        set_status_id(response.data.status_id);
        // set_status(response.data.status);
        if (editMode === true && [2, 10].includes(response.data.status_id)) {
          // setResetButtonState(true);
        }
        if (editMode === true && response.data.reniec_answer !== true) {
          setReniecStatusButtonsDisabled(false);
        }
      })
      .catch((error) => {
        console.log(error.response);
      });
    HttpCliente.get(`/request/${kyc_id}/history`)
      .then((response) => {
        setHistoryData(response.data);
      })
      .catch((error) => {
        setHistoryData(undefined);
        console.log(error.response);
      });
    HttpCliente.get(`/status/request`)
      .then((response) => {
        setStatusRequestData(response.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
    HttpCliente.get(`/status/kyc`)
      .then((response) => {
        setStatusKycData(response.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
    HttpCliente.get(`/bank`)
      .then((response) => {
        setBankData(response.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
    HttpCliente.get(`/partner`)
      .then((response) => {
        setPartnerData(response.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
    HttpCliente.get(`/note/?per_page=250&page=1`)
      .then((response) => {
        setStatusNoteData(response.data.data);
      })
      .catch((error) => {
        console.log(error.response);
      });
  }, [kyc_id, temp_kyc_id, newCommentPopup, statusResetPopup, status_id]);
  ////////////////////////////////////////////////SAVE AND SUBMIT BUTTON HANDLERS////////////////////////////////////////////////////////////////////////////////////////
  //itd 256 begin
  const navigate = useNavigate();
  //itd 256 end

  //itd-256 begin
  const handleSubmitEditButton = () => {
    navigate(`${path}`);
  }

  //itd-256  end
  const onAddNoteHandler = () => {
    let msg_val = '';
    if (predifinedNote === null || predifinedNote === undefined || predifinedNote === '') msg_val = `${msg_val} Predefined Notes must not be empty.`;
    if (msg_val != '') {
      toggleSweetAlert(
        msg_val,
        true,
        "info"
      );
      return false;
    }
    let dataInVal = statusNoteData.filter(li => li.note_id == predifinedNote);
    if (typeof dataInVal !== `undefined`) {
      let _kyc_status_user_notes = '';
      if (kyc_status_user_notes == null) {
        _kyc_status_user_notes = dataInVal[0].description;
      }
      else {
        _kyc_status_user_notes = kyc_status_user_notes + '-' + dataInVal[0].description;
      }
      set_kyc_status_user_notes(_kyc_status_user_notes);
    }
  }

  const onSubmitHandler = () => {

    //itd-255 begin
    if (kyc_status_customer_id == 0 || kyc_status_customer_name == 0 || kyc_status_bank_account == 0 || kyc_status_bank_name == 0) {
      toggleSweetAlert(
        "You can not submit this KYC because one or more KYC flag is still pending",
        true,
        "danger"
      );
      return false;
    }
    //itd-255 end

    setAlertType("save");
    const m1 = "Are you sure you want to submit";
    const m2 = "this request record?";
    const type = "info";
    toggleSweetAlertVariant3(m1, m2, type);
  };
  // const processQueueHandler = () => {
  //   HttpCliente.get(`/request/0`)
  //     .then((response) => {
  //       if (response.status === 404) {
  //         toggleSweetAlert("Request Submitted", true, "success");
  //       } else {
  //         // setAlertType("nextRequest");
  //         // set_temp_kyc_id(response.data.id);
  //         // toggleSweetAlertVariant2(
  //         //   "Request Submitted!",
  //         //   " Do you want to edit the next record?",
  //         //   "",
  //         //   "success"
  //         // );
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(`Error Fetching Next Request: ${error.response}`);
  //     });
  // };
  const submitButtonPatchRequest = () => {
    let sigData;
    // console.log(reniec_answer)
    if (reniec_answer !== true) {
      sigData = {
        kyc_status_customer_id: kyc_status_customer_id,
        kyc_status_customer_name: kyc_status_customer_name,
        kyc_status_bank_account: kyc_status_bank_account,
        kyc_status_bank_name: kyc_status_bank_name,
        kyc_status_notes: kyc_status_notes,
        kyc_status_user_notes: kyc_status_user_notes,
        status_id: 4,
        customer_id_type: customer_id_type,
        customer_dob: customer_dob,
      };
    } else {
      sigData = {
        kyc_status_bank_account: kyc_status_bank_account,
        kyc_status_bank_name: kyc_status_bank_name,
        kyc_status_notes: kyc_status_notes,
        kyc_status_user_notes: kyc_status_user_notes,
        status_id: 4,
      };
    }
    axios
      .patch(`/request/${kyc_id}`, sigData)
      .then((response) => {
        //console.log(response);
        // processQueueHandler();
        setAlertType("nextRequest");
        set_temp_kyc_id(response.data.id);
        //itd-256 begin
        if (temp_typeEdit == 'processQueue') {
          toggleSweetAlertVariant2(
            "Request Submitted!",
            " Do you want to edit the next record?",
            "",
            "success"
          );
        } else {
          setAlertType("submitEdit");
          toggleSweetAlert("KYC Request Updated", true, "success");

        }
        //itd-256 end
      })
      .catch((error) => {
        toggleSweetAlert(
          `Error: ${error.response.data.errors
            ? error.response.data.errors.message
            : "Failed"
          }`,
          false,
          "danger"
        );
        //console.log(`Error: ${error}`, false);
      });
  };
  const onSaveHandler = () => {
    let sigData;
    if (reniec_answer !== true) {
      sigData = {
        kyc_status_customer_id: kyc_status_customer_id,
        kyc_status_customer_name: kyc_status_customer_name,
        kyc_status_bank_account: kyc_status_bank_account,
        kyc_status_bank_name: kyc_status_bank_name,
        kyc_status_notes: kyc_status_notes,
        kyc_status_user_notes: kyc_status_user_notes,
      };
    } else {
      sigData = {
        kyc_status_bank_account: kyc_status_bank_account,
        kyc_status_bank_name: kyc_status_bank_name,
        kyc_status_notes: kyc_status_notes,
        kyc_status_user_notes: kyc_status_user_notes,
      };
    }
    axios
      .patch(`/request/${kyc_id}`, sigData)
      .then((response) => {
        toggleSweetAlert("KYC Request Updated", true, "success");
      })
      .catch((error) => {
        if (error.response.data.status == 400) {
          toggleSweetAlert(
            `Error: ${error.response.data.errors
              ? error.response.data.errors.message
              : "Failed"
            }`,
            false,
            "danger"
          );
          if (typeof window !== `undefined`) {
            setTimeout(() => {
              window.location = `${path}/view/${temp_kyc_id}`;
            }, 2000);
          }
        } else
          toggleSweetAlert(
            `${error.response.data.errors.message
              ? error.response.data.errors.message
              : error
            }`,
            false,
            "danger"
          );
      });
  };

  //itd-255 begin
  const { showingSave } = false;
  //itd-255 end
  return (
    <div id="animate">
      {newCommentPopup ? (
        <ModalGeneral
          isModalOpen={newCommentPopup}
          onlyModal={true}
        >
          <NewCommentPopup
            statusRequestData={statusRequestData}
            current_kyc_id={kyc_id}
            status_id={status_id}
            toggle={toggleNewCommentPopup}
            toggleSweetAlert={toggleSweetAlert}
          />
        </ModalGeneral>
      ) : null}
      {statusResetPopup ? (
        <ModalGeneral
          isModalOpen={statusResetPopup}
          onlyModal={true}
        >
          <StatusResetPopup
            resetList={selectedResetRecords}
            toggle={toggleStatusResetPopup}
            toggleSweetAlert={toggleSweetAlert}
          />
        </ModalGeneral>
      ) : null}

      {sweetAlertActive ? (
        <ModalGeneral
          isModalOpen={sweetAlertActive}
          onlyModal={false}
          confirmBtnBsStyle={`${sweetAlertSuccessBool ? "success" : "error"}`}
          type={`${sweetAlertSuccess}`}
          onConfirm={() => {
            setSweetAlertActive(false);
            //itd-256 begin
            if (alertType === "submitEdit") {
              handleSubmitEditButton();
              setAlertType("");
            }
            //itd-256 end           
          }}
          onClose={() => {
            setSweetAlertActive(false);
          }}
        >
          <div>
            <h3>{sweetAlertMessage}</h3>
          </div>
        </ModalGeneral>
      ) : null}
      {sweetAlertActiveVariant2 ? (
        <ModalGeneral
          isModalOpen={sweetAlertActiveVariant2}
          onlyModal={false}
          type={sweetAlertSuccessVariant2}
          title={sweetAlertMessage1Variant2}
          onConfirm={() => {
            if (alertType === "nextRequest") {
              HttpCliente.get(`/request/0`)
                .then((response) => {
                  if (response.status === 404) {
                    toggleSweetAlert(
                      "There is no more KYC Request in the queue",
                      true,
                      "info"
                    );
                  } else {
                    set_temp_kyc_id(response.data.id);
                    //itd-256 begin
                    localStorage.setItem("typeEdit", "processQueue");
                    set_typeEdit('processQueue');
                    //itd-256 end
                    if (typeof window !== `undefined`) {
                      //itd-256 begin
                      navigate(`${path}/${edit_mode}/${response.data.id}`);
                      //itd-256 end
                    }
                  }
                })
                .catch((error) => {
                  console.log(`Error Fetching Next Request: ${error.response}`);
                });
            }
            setSweetAlertActiveVariant2(false);
          }}
          onCancel={() => {
            if (alertType !== "nextRequest") {
              //itd-256 begin
              navigate(`${path}/view/${temp_kyc_id}`);
              //itd-256 end
              setReniecStatusButtonsDisabled(true);
            } else {
              //itd-256 begin
              navigate(`${path}/view/${kyc_id}`);
              //itd-256 end
            }
            setAlertType("");
            setSweetAlertActiveVariant2(false);
          }}
          cancelBtnText="No"
          confirmBtnText="Yes"
        >
          <h4>{sweetAlertMessage2Variant2}</h4>
          <h4>{sweetAlertMessage3Variant2}</h4>
        </ModalGeneral>
      ) : null}
      {sweetAlertActiveVariant3 ? (
        <ModalGeneral
          isModalOpen={sweetAlertActiveVariant3}
          onlyModal={false}
          type={sweetAlertSuccessVariant3}
          title={sweetAlertMessage1Variant3}
          onConfirm={() => {
            if (alertType === "save") {
              submitButtonPatchRequest();
              setAlertType("");
            }
            setSweetAlertActiveVariant3(false);
          }}
          onCancel={() => {
            setAlertType("");
            setSweetAlertActiveVariant3(false);
          }}
          onClose={() => {
            setAlertType("");
            setSweetAlertActiveVariant3(false);
          }}
          cancelBtnText="No"
          confirmBtnText="Yes"
        >
          <h4>{sweetAlertMessage2Variant3}</h4>
        </ModalGeneral>
      ) : null}
      <h1 className="page-header">KYC Request</h1>
      <Panel>
        <PanelHeader noButton className="panel-heading">
          Main Info
        </PanelHeader>
        <PanelBody>
          <div className="row">
            <div className="mb-3 col-md-4">
              <label className="form-label">
                <strong>KYC #</strong>
              </label>
              <input
                disabled={true}
                type="text"
                className="form-control"
                id="InputKycNumber"
                value={id}
                onChange={(e) => {
                  set_id(e.target.value);
                }}
              />
            </div>
            <div className="mb-3 col-md-4">
              <label className="form-label">
                <strong>Date / Time</strong>
              </label>
              <input
                disabled={true}
                type="text"
                className="form-control"
                id="InputDateFrom"
                min="1997-01-01"
                max="2030-12-29"
                placeholder={moment
                  .unix(request_date)
                  .format("DD/MM/YYYY hh:mm A")}
                onChange={(e) => {
                  set_request_date(moment(e.target.value).unix());
                }}
                onFocus={(e) => (e.currentTarget.type = "date")}
                onBlur={(e) => {
                  e.currentTarget.type = "text";
                  e.currentTarget.placeholder = moment(request_date).format(
                    "DD/MM/YYYY hh:mm A"
                  );
                }}
              />
            </div>
            <div className="mb-3 col-md-4">
              <label className="form-label">
                <strong>Request Status</strong>
              </label>
              <select
                disabled={true}
                className="form-select"
                id="InputRequestStatus"
                value={status_id}
                onChange={(e) => {
                  set_status_id(e.target.value);
                }}
              >
                {typeof statusRequestData !== `undefined`
                  ? statusRequestData.map((value, index) => (
                    <option
                      value={value.requestStatusId}
                      key={value.requestStatusId}
                    >
                      {value.requestStatusName}
                    </option>
                  ))
                  : null}
              </select>
            </div>
            <div className="mb-3 col-md-4">
              <label className="form-label">
                <strong>Client</strong>
              </label>
              <select
                disabled={true}
                className="form-select"
                id="InputClient4"
                value={partner_id}
                onChange={(e) => {
                  set_partner_id(e.target.value);
                }}
              >
                {typeof partnerData !== `undefined`
                  ? partnerData.map((value, index) => (
                    <option value={value.partnerID} key={value.partnerID}>
                      {value.partnerName}
                    </option>
                  ))
                  : null}
              </select>
            </div>
            <div className="mb-3 col-md-4">
              <label className="form-label">
                <strong>Request Reference</strong>
              </label>
              <input
                disabled={true}
                type="text"
                className="form-control"
                id="InputRequestReference"
                value={request_reference}
                onChange={(e) => {
                  set_request_reference(e.target.value);
                }}
              />
            </div>
            <div className="mb-3 col-md-4">
              <label className="form-label">
                <strong>KYC Status</strong>
              </label>
              <select
                disabled={true}
                className="form-select"
                id="InputKycStatus"
                value={kyc_status_id}
                onChange={(e) => {
                  set_kyc_status_id(e.target.value);
                }}
              >
                {typeof statusKycData !== `undefined`
                  ? statusKycData.map((value, index) => (
                    <option value={value.kycStatusId} key={value.kycStatusId}>
                      {value.kycStatusName}
                    </option>
                  ))
                  : null}
              </select>
            </div>
          </div>
        </PanelBody>
      </Panel>
      <Panel>
        <PanelBody>
          <div id="animate">
            <TabbedPanel
              tabs={["KYC Flags", "History"]}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            >
              {activeTab === 0 ? (
                <div>
                  <div>
                    <PanelHeader noButton>Customer Identification</PanelHeader>
                    <PanelBody>
                      <div className="row">
                        <div className="mb-3 col-md-4">
                          <label className="form-label" htmlFor="InputIdNumber">
                            <strong>ID Type</strong>
                          </label>
                          <br />
                          <select
                            className="form-select"
                            name="account-name-status"
                            id="InputDocumentType"
                            disabled={!editMode ||PageSettingsContext.userDataLogin.userData.user_tipo_id !== 3 ? true : false}
                            value={customer_id_type}
                            onChange={(e) => {
                              set_customer_id_type(e.target.value);
                          }}
                          >
                            <option value="" disabled hidden>
                              Select
                            </option>
                            <option>DNI</option>
                            <option>CE</option>
                            <option>PAS</option>
                          </select>
                        </div>
                        <div className="mb-3 col-md-4">
                          <label className="form-label" htmlFor="InputIdNumber">
                            <strong>ID Number</strong>
                          </label>
                          <br />
                          <input
                            type="number"
                            className="form-control col-md-8"
                            id="InputDocumentNumber"
                            placeholder="Enter ID"
                            disabled={true}
                            value={customer_id_number}
                          />
                        </div>
                        <div className="mb-3 col-md-4">
                          <label className="form-label">
                            <strong>Date of Birth</strong>
                          </label>
                          <input
                            disabled={!editMode ||PageSettingsContext.userDataLogin.userData.user_tipo_id !== 3 ? true : false}
                            type="text"
                            className="form-control"
                            id="InputDateFrom"
                            min="1997-01-01"
                            max="2030-12-29"
                            placeholder={moment(customer_dob ? customer_dob : request_date).format("DD/MM/YYYY")}
                            onChange={(e) => {
                              set_customer_dob(moment(e.target.value));
                            }}
                            onFocus={(e) => (e.currentTarget.type = "date")}
                            onBlur={(e) => {
                              e.currentTarget.type = "text";
                              e.currentTarget.placeholder = moment(
                                request_date
                              ).format("DD/MM/YYYY");
                            }}
                          />
                        </div>
                        <div className="mb-3 col-md-4">
                          <label className="form-label">
                            <strong>Customer Name</strong>
                          </label>
                          <input
                            disabled={true}
                            type="text"
                            className="form-control"
                            id="InputCustomerName"
                            value={customer_fullname}
                            onChange={(e) => {
                              set_customer_fullname(e.target.value);
                            }}
                          />
                        </div>
                        <div className="mb-3 col-md-4">
                          <label className="form-label">
                            <strong>Registry Name</strong>
                          </label>
                          <input
                            disabled={true}
                            type="text"
                            className="form-control"
                            id="InputRegistryName"
                            value={customer_registry}
                            onChange={(e) => {
                              set_customer_registry(e.target.value);
                            }}
                          />
                        </div>
                        <div className="mb-3 col-md-4">
                          <label className="form-label">
                            <strong>Customer ID</strong>
                          </label>
                          <input
                            disabled={true}
                            type="text"
                            className="form-control"
                            id="InputCustomerId"
                            value={customer_id}
                            onChange={(e) => {
                              set_customer_id(e.target.value);
                            }}
                          />
                        </div>
                        <div className="mb-3 col-md-4">
                          <label className="form-label">
                            <strong>Email</strong>
                          </label>
                          <input
                            disabled={true}
                            type="text"
                            className="form-control"
                            id="InputEmail"
                            value={customer_email}
                            onChange={(e) => {
                              set_customer_email(e.target.value);
                            }}
                          />
                        </div>
                        <div className="mb-3 col-md-4">
                          <label className="form-label">
                            <strong>Province</strong>
                          </label>
                          <input
                            disabled={true}
                            type="text"
                            className="form-control"
                            id="InputProvince"
                            value={customer_province}
                            onChange={(e) => {
                              set_customer_province(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="mb-3 col-md-4">
                          <label className="form-label">
                            <strong>ID: Status</strong>
                          </label>
                          <br />
                          <select
                            disabled={reniecStatusButtonsDisabled || !editMode}
                            className="form-select"
                            name="id-status"
                            id="InputIdStatus"
                            value={kyc_status_customer_id}
                            onChange={(e) => {
                              set_kyc_status_customer_id(e.target.value);
                            }}
                          >
                            {typeof statusKycData !== `undefined`
                              ? statusKycData.map((value, index) => (
                                <option
                                  value={value.kycStatusId}
                                  key={value.kycStatusId}
                                >
                                  {value.kycStatusName}
                                </option>
                              ))
                              : null}
                          </select>
                        </div>
                        <div className="mb-3 col-md-4">
                          <label className="form-label">
                            <strong>Name: Status</strong>
                          </label>
                          <br />
                          <select
                            disabled={reniecStatusButtonsDisabled || !editMode}
                            className="form-select"
                            name="name-status"
                            id="InputNameStatus"
                            value={kyc_status_customer_name}
                            onChange={(e) => {
                              set_kyc_status_customer_name(e.target.value);
                            }}
                          >
                            {typeof statusKycData !== `undefined`
                              ? statusKycData.map((value, index) => (
                                <option
                                  value={value.kycStatusId}
                                  key={value.kycStatusId}
                                >
                                  {value.kycStatusName}
                                </option>
                              ))
                              : null}
                          </select>
                        </div>
                      </div>
                    </PanelBody>
                  </div>
                  <div>
                    <PanelHeader noButton>Customer Bank Account</PanelHeader>
                    <PanelBody>
                      <div className="row">
                        <div className="mb-3 col-md-4">
                          <label className="form-label" htmlFor="InputBankCci">
                            {/* itd 256 begin*/}
                            <strong>Bank CCI</strong>
                            {/* itd 256 end*/}
                          </label>
                          <input
                            disabled={true}
                            type="text"
                            className="form-control"
                            id="InputBankCci"
                            value={bank_account_cci}
                            onChange={(e) => {
                              set_bank_account_cci(e.target.value);
                            }}
                          />
                        </div>
                        <div className="mb-3 col-md-4">
                          <label className="form-label" htmlFor="InputBankAccountNumber">
                            <strong>Bank Account #</strong>
                          </label>
                          <input
                            disabled={true}
                            type="text"
                            className="form-control"
                            id="InputBankAccountNumber"
                            value={bank_account_number}
                            onChange={(e) => {
                              set_bank_account_number(e.target.value);
                            }}
                          />
                        </div>
                        <div className="mb-3 col-md-4">
                          <label className="form-label" htmlFor="InputBankName">
                            <strong>Bank</strong>
                          </label>
                          <select
                            disabled={true}
                            type="text"
                            className="form-select"
                            id="InputBankName"
                            value={bank_code}
                            onChange={(e) => {
                              set_bank_code(e.target.value);
                            }}
                          >
                            <option value="null" selected disabled hidden>
                              Unrecognized
                            </option>
                            <option value={""} key={"all"}>
                              Unrecognized
                            </option>
                            {typeof bankData !== `undefined`
                              ? bankData.map((value, index) => (
                                <option
                                  value={value.bankCode}
                                  key={value.bankCode}
                                >
                                  {value.bankName}
                                </option>
                              ))
                              : null}
                          </select>
                        </div>
                      </div>
                      <div className="row">
                        <div className="mb-3 col-md-4">
                          <label className="form-label" htmlFor="InputAccountNumberStatus">
                            <strong>Account #: Status</strong>
                          </label>
                          <br />
                          <select
                            disabled={!editMode}
                            className="form-select"
                            name="account-number-status"
                            id="InputAccountNumberStatus"
                            value={kyc_status_bank_account}
                            onChange={(e) => {
                              set_kyc_status_bank_account(e.target.value);
                            }}
                          >
                            {typeof statusKycData !== `undefined`
                              ? statusKycData.map((value, index) => (
                                <option
                                  value={value.kycStatusId}
                                  key={value.kycStatusId}
                                >
                                  {value.kycStatusName}
                                </option>
                              ))
                              : null}
                          </select>
                        </div>
                        <div className="mb-3 col-md-4">
                          <label className="form-label" htmlFor="InputAccountNameStatus">
                            <strong>Name: Status</strong>
                          </label>
                          <br />
                          <select
                            disabled={!editMode}
                            className="form-select"
                            name="account-name-status"
                            id="InputAccountNameStatus"
                            value={kyc_status_bank_name}
                            onChange={(e) => {
                              set_kyc_status_bank_name(e.target.value);
                            }}
                          >
                            {typeof statusKycData !== `undefined`
                              ? statusKycData.map((value, index) => (
                                <option
                                  value={value.kycStatusId}
                                  key={value.kycStatusId}
                                >
                                  {value.kycStatusName}
                                </option>
                              ))
                              : null}
                          </select>
                        </div>

                        {editMode ? (
                          <>
                            <div className="col-md-2">
                              <label className="form-label" htmlFor="InputNote">
                                <strong>Predefined Notes</strong>
                              </label>
                              <br />
                              <select
                                disabled={!editMode}
                                className="form-select"
                                name="note"
                                id="InputNote"
                                value={predifinedNote}
                                onChange={(e) => {
                                  setPredifinedNote(e.target.value);
                                }}
                              >
                                <option value='' key='' />
                                {typeof statusNoteData !== `undefined`
                                  ? statusNoteData.map((value, index) => (
                                    <option
                                      value={value.note_id}
                                      key={value.note_id}
                                    >
                                      {value.description}
                                    </option>
                                  ))
                                  : null}
                              </select>
                            </div>
                            <div className="col-md-2">
                              <br />
                              <button
                                className="panel-button"
                                onClick={() => {
                                  onAddNoteHandler();
                                }}                        >

                                <strong>Add</strong>
                              </button>
                            </div>
                          </>

                        ) : null}

                      </div>
                    </PanelBody>
                  </div>
                  <div>
                    <PanelHeader noButton>Notes</PanelHeader>
                    <PanelBody>
                      <div className="row">
                        <div className="col-md-12">
                          <label className="form-label" htmlFor="InputNote">
                            <strong>System Notes</strong>
                          </label>
                          <br />
                          <textarea
                            disabled={!editMode}
                            className="form-control"
                            id="InputNotes"
                            value={kyc_status_notes}
                            onChange={(e) => {
                              set_kyc_status_notes(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <label className="form-label" htmlFor="InputUserNote">
                            <strong>User Notes</strong>
                          </label>
                          <br />
                          <textarea
                            disabled={!editMode}
                            className="form-control"
                            id="InputUserNote"
                            value={kyc_status_user_notes}
                            onChange={(e) => {
                              set_kyc_status_user_notes(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </PanelBody>
                  </div>
                </div>
              ) : (
                <div>
                  <div>
                    <PanelHeader noButton>Verification Method</PanelHeader>
                    <PanelBody>
                      <div className="row">
                        <div className="mb-3 col-md-4">
                          <label className="form-label" htmlFor="InputCustomerIdentification">
                            <strong>Customer Identification</strong>
                          </label>
                          <select
                            disabled={true}
                            type="text"
                            className="form-select"
                            id="InputCustomerIdentification"
                            value={method_customer_id}
                            onChange={(e) => {
                              set_method_customer_id(e.target.value);
                            }}
                          >
                            <option value={1} key={1}>
                              PD Manual
                            </option>
                            <option value={2} key={2}>
                              PD Database
                            </option>
                            <option value={3} key={3}>
                              RENIEC
                            </option>
                          </select>
                        </div>
                        <div className="mb-3 col-md-4">
                          <label className="form-label" htmlFor="InputCustomerBankAccount">
                            <strong>Customer Bank Account</strong>
                          </label>
                          <select
                            disabled={true}
                            type="text"
                            className="form-select"
                            id="InputCustomerBankAccount"
                            value={method_bank_account}
                            onChange={(e) => {
                              set_method_bank_account(e.target.value);
                            }}
                          >
                            <option value={1} key={1}>
                              PD Manual
                            </option>
                            <option value={2} key={2}>
                              PD Database
                            </option>
                            <option value={3} key={3}>
                              RENIEC
                            </option>
                          </select>
                        </div>
                      </div>
                    </PanelBody>
                  </div>
                  <div>
                    <PanelHeader noButton>History and Comments</PanelHeader>
                    <PanelBody>
                      <div className="panel-button-row">
                        <div />
                        <div>
                          {editMode ? (
                            <button
                              className="panel-button"
                              onClick={() => {
                                toggleNewCommentPopup(!newCommentPopup);
                              }}
                            >
                              <strong>Add Comment</strong>
                            </button>
                          ) : null}
                        </div>
                      </div>
                      <div className="table-responsive">
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <td>Date / Time</td>
                              <td>User</td>
                              <td>Comment</td>
                              <td>Status</td>
                            </tr>
                          </thead>
                          <tbody>
                            {typeof historyData !== `undefined`
                              ? historyData.map((value, index) => {
                                return (
                                  <tr>
                                    <td>
                                      {moment
                                        .unix(value.history_date)
                                        .format("DD/MM/YYYY hh:mm A")}
                                    </td>
                                    <td>{value.user_fullname}</td>
                                    <td>{value.comment}</td>
                                    <td>{value.status_name}</td>
                                  </tr>
                                );
                              })
                              : null}
                          </tbody>
                        </table>
                      </div>
                    </PanelBody>
                  </div>
                </div>
              )}
            </TabbedPanel>
          </div>
        </PanelBody>
      </Panel>
      <div className="panel-button-row">
        <div>
          <Link to={`${path}`}>
            <button
              className="panel-iconbutton-close"
              onClick={() => {
                //toggleRequestBoard();
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="white"
                width="18px"
                height="18px"
              >
                <path d="M0 0h24v24H0z" fill="none" />
                <path d="M21 11H6.83l3.58-3.59L9 6l-6 6 6 6 1.41-1.41L6.83 13H21z" />
              </svg>
              <strong>Close</strong>
            </button>
          </Link>
          {[2, 10].includes(status_id) ? (
            <button
              className="panel-button-reset"
              onClick={() => {
                addRecordToReset(kyc_id);
                toggleStatusResetPopup(!statusResetPopup);
              }}
            >
              <strong>Reset</strong>
            </button>
          ) : null}
        </div>
        {editMode ? (
          <div>

            {
              //itd-255 begin
              showingSave ?
                <button
                  className="panel-iconbutton-save"
                  onClick={() => {
                    onSaveHandler();
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="white"
                    width="18px"
                    height="18px"
                  >
                    <path d="M0 0h24v24H0V0z" fill="none" />
                    <path d="M17 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V7l-4-4zm2 16H5V5h11.17L19 7.83V19zm-7-7c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3zM6 6h9v4H6z" />
                  </svg>
                  <strong>Save</strong>
                </button>
                : null
              //itd-255 end
            }

            <button
              className="panel-iconbutton-search"
              onClick={() => {
                onSubmitHandler();
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="white"
                width="18px"
                height="18px"
              >
                <path d="M0 0h24v24H0V0z" fill="none" />
                <path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z" />
              </svg>
              <strong>Submit</strong>
            </button>
          </div>
        ) : (
          <div />
        )}
      </div>
    </div>
  );
};

export default RequestForm;
